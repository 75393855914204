<template>
  <v-container id="buttons" fluid tag="section">
    <v-data-table dense width="100%" :headers="balance_header" :items="balances" :loading="loading"
      loading-text="Loading... Please wait" class="elevation-1" disable-pagination :items-per-page="NaN"
      hide-default-footer>
    </v-data-table>
  </v-container>
</template>

<script>
export default {
  name: "BalancePanel",

  props: {
    balances: Array,
    loading: Boolean,
  },

  data: () => ({
    balance_header: [
      { text: "Symbol", value: "symbol" },
      { text: "Balance", value: "balance" },
    ],
  }),
};
</script>
