<template>
  <v-container id="account" fluid tag="section">
    <v-skeleton-loader v-if="firstLoad" :loading="loading" type="table"></v-skeleton-loader>

    <v-tabs v-else>
      <v-tab v-for="account in account_names" :key="account">{{
          account
      }}</v-tab>

      <v-tab-item v-for="(balances, i) in balancesLst" :key="account_names[i]">
        <v-row>
          <v-col cols="12">
            <balance-panel :balances="balances"></balance-panel>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
import { getBalances, myMixins } from "@/api";
import BalancePanel from "../component/BalancePanel.vue";

export default {
  components: {
    BalancePanel,
  },
  mixins: [myMixins],
  mounted: function () {
    const self = this;

    self.getAccounts();

    self.timer = setInterval(() => {
      setTimeout(() => {
        self.getAccounts();
      }, 0);
    }, 10000);
  },
  destroyed() {
    clearInterval(this.timer);
  },
  methods: {
    getAccounts() {
      const self = this;
      self.loading = true;

      getBalances()
        .then((items) => {
          if (self.firstLoad) {
            self.firstLoad = false;
          }

          self.items = items;
          self.loading = false;
        })
        .catch((reason) => {
          console.error(reason);
          self.loading = false;
          self.logout();
        });
    },
  },

  watch: {
    items(val) {
      const account_names = [];
      const balancesLst = [];

      val.forEach((element) => {
        const balances = [];
        Object.keys(element.balances).forEach((value, index) => {
          balances.push({ symbol: value, balance: element.balances[value] });
        });
        account_names.push(element.account);
        balancesLst.push(balances);
      });

      this.account_names = account_names;
      this.balancesLst = balancesLst;
    },
  },
  data() {
    return {
      firstLoad: true,
      loading: true,
      timer: -1,
      items: [],
      account_names: [],
      balancesLst: [],
    };
  },
};
</script>
